var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center py-10" }, [
    _c(
      "svg",
      {
        staticClass: "mx-auto",
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "78",
          height: "62",
          viewBox: "0 0 78 62",
          fill: "none",
        },
      },
      [
        _c("path", {
          attrs: {
            d: "M4 34.9701L28.6429 54L73 4",
            stroke: "#6A30C3",
            "stroke-width": "11",
          },
        }),
        _c("path", {
          attrs: {
            d: "M4 34.9701L28.6429 54L73 4",
            stroke: "url(#paint0_linear_21_2290)",
            "stroke-width": "11",
          },
        }),
        _c(
          "defs",
          [
            _c(
              "linearGradient",
              {
                attrs: {
                  id: "paint0_linear_21_2290",
                  x1: "-427.857",
                  y1: "244",
                  x2: "185.813",
                  y2: "510.308",
                  gradientUnits: "userSpaceOnUse",
                },
              },
              [
                _c("stop", {
                  attrs: { offset: "0.38059", "stop-color": "#C6003B" },
                }),
                _c("stop", {
                  attrs: { offset: "0.491928", "stop-color": "#6A30C3" },
                }),
                _c("stop", {
                  attrs: { offset: "0.600732", "stop-color": "#63DFDF" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _vm._m(0),
    _c("p", { staticClass: "text-grey mt-4" }, [
      _vm._v(" You have set a spending limit of N20,000 on your account. "),
    ]),
    _c(
      "div",
      { staticClass: "mt-10" },
      [
        _c("Button", {
          staticClass: "place-self-end",
          attrs: { text: "Done", width: "w-full", p: "py-3 px-8", outline: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("successFunction")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h3",
      { staticClass: "text-2xl font-bold mt-10 text-brandPurple" },
      [_vm._v(" Limit setup "), _c("br"), _vm._v(" completed ")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }