var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-full w-full grid p-2" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "flex items-end mt-20" },
      [
        _c("Button", {
          staticClass: "w-full",
          attrs: { text: "Proceed" },
          on: {
            click: function ($event) {
              return _vm.$emit("code")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h6", { staticClass: "text-brandPurple text-xl font-bold" }, [
        _vm._v(" Setup bank account tokenization "),
      ]),
      _c("p", { staticClass: "text-grey mt-4" }, [
        _vm._v(
          " We have sent an SMS containing unique OTP and USSD codes to your phone number: 08022*****54. Kindly confirm the codes to proceed. "
        ),
      ]),
      _c("h2", { staticClass: "text-grey font-bold py-5" }, [
        _vm._v("Why you need to do this?"),
      ]),
      _c("ul", { staticClass: "list-disc pl-4" }, [
        _c("li", [
          _vm._v(
            "We want to ensure that your payment experience is smooth and easy for this first payment and for every other payment you make with Zilla."
          ),
        ]),
        _c("li", { staticClass: "mt-4" }, [
          _vm._v(
            "When you proceed, you will be sent steps to setup debit instructions on your bank account. Kindly complete this process to setup your spending limit."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }