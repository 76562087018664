<template>
  <div>
    <div class="spending-container">
      <BackNav @click="$router.push('/drawer')" />
      <div class="mt-6">
        <Card>
          <p class="text-grey">Credit balance</p>

          <div v-if="creditWallet">
            <h2
              v-if="creditWallet.totalBalance"
              class="text-brandPurple font-bold text-xl"
            >
              {{ Math.abs(creditWallet.totalBalance) | formatMoney }}
            </h2>
            <Skeleton v-else height="1.5rem" width="30%" />
          </div>

          <div v-if="creditWallet">
            <p v-if="creditWallet.balance" class="text-grey mb-5">
              {{  Math.abs(creditWallet.balance) | formatMoney }} spent
            </p>
            <Skeleton v-else height="1rem" width="40%" class="mt-2" />
          </div>
          <!-- <div class="flex justify-end items-center mt-4">
          <p class="text-brandPurple text-sm font-bold mr-4">Credit Details</p>
          <SecondaryButton
            text="Get more"
            fontSize="text-sm"
            @click="$router.push('/drawer/card/spending-limit/details')"
          />
        </div> -->
        </Card>
      </div>
      <!-- <Card class="mt-6">
        <p class="font-semibold">No spending limit yet</p>
        <div class="flex items-center mt-2">
          <p class="text-grey mr-4 text-sm">
            Set your spending limit on your account to help you manage your
            account better.
          </p>
          <SecondaryButton
            text="Set limit"
            fontSize="text-sm"
            width="w-48 md:w-28"
            @click="showLimit = true"
          />
        </div>
      </Card> -->
      <!-- <Card class="mt-6">
        <p>Spending limit</p>
        <HorizontalProgress :progress="80" />
        <p class="mt-3 flex items-center">
          <span class="font-semibold text-brandPurple">N20,000</span>
          <span
            class="py-1 px-4 mx-2 text-xs border border-brandPurple rounded-full text-brandPurple"
            >LEFT</span
          >
          <span>of N100,000 limit</span>
        </p>
        <div class="mt-2 flex justify-end">
          <SecondaryButton
            text="Edit limit"
            fontSize="text-sm"
            width="w-24"
            @click="showLimit = true"
          />
        </div>
        <Hr />

        <p class="text-grey text-sm">
          You need spending limit set for future purchases.
        </p>
        <p class="text-grey text-sm mt-2">
          This shows the amount you spent, it doesnt account which is different
          from your credit.
        </p>
      </Card> -->
      <Card class="mt-6">
        <div v-if="instaPayWallet">
          <h2 v-if="instaPayWallet.balance" class="font-bold text-xl">
            {{ instaPayWallet.balance | formatMoney }}
          </h2>
          <Skeleton v-else height="1.5rem" width="30%" />
        </div>
        <p class="text-grey">Wallet balance</p>
        <div class="flex justify-end items-center mt-4">
          <p
            class="text-brandPurple text-sm font-bold mr-4 cursor-pointer"
            @click="showFundHistory = true"
          >
            Wallet history
          </p>
          <SecondaryButton
            text="Fund wallet"
            fontSize="text-sm"
            @click="showFundAccount = true"
          />
        </div>
      </Card>
    </div>
    <Modal :display="showLimit" @close="showLimit = false" hideClose>
      <SetLimit @create="(showLimit = false), (showTokenizationModal = true)" />
    </Modal>
    <Modal
      :display="showTokenizationModal"
      @close="showTokenizationModal = false"
      hideClose
    >
      <ShowTokenization
        @code="(showTokenizationModal = false), (showOtpModal = true)"
      />
    </Modal>
    <Modal :display="showOtpModal" @close="showOtpModal = false" hideClose>
      <Showotp @success="(showOtpModal = false), (showSuccessModal = true)" />
    </Modal>
    <Modal
      :display="showSuccessModal"
      @close="showSuccessModal = false"
      hideClose
    >
      <Success @successFunction="showSuccessModal = false" />
    </Modal>
    <Modal
      :display="showFundAccount"
      @close="closeFundWallet"
      title="Add money to account"
    >
      <FundAccount @completed="completed" @close="showFundAccount = false" />
    </Modal>
    <Modal
      :display="showFundHistory"
      @close="showFundHistory = false"
      title="Your Wallet"
    >
      <div v-if="instaPayWallet">
        <FundHistory
          @fund="showFundHistory = false"
          :balance="instaPayWallet.balance"
          :showBalance="false"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { BackNav, PreviousPage } from "@/UI/Navigation";
import { SecondaryButton } from "@/UI/Button";
import { Modal } from "@/UI/Modals";
import { HorizontalProgress } from "@/UI/Progress";
import Hr from "@/UI/Hr";
import SetLimit from "../SpendingLimit/SetLimit.vue";
import ShowTokenization from "@/components/SpendingLimit/ShowTokenization.vue";
import Showotp from "@/components/SpendingLimit/otp.vue";
import Success from "@/components/SpendingLimit/Success.vue";
export default {
  components: {
    PreviousPage,
    SecondaryButton,
    Modal,
    HorizontalProgress,
    Hr,
    SetLimit,
    ShowTokenization,
    Showotp,
    Success,
    BackNav,
    FundAccount: () => import("../Home/FundAccount"),
    FundHistory: () => import("../Home/FundHistory/fund.vue"),
  },
  data() {
    return {
      showLimit: false,
      showTokenizationModal: false,
      showOtpModal: false,
      showSuccessModal: false,
      showFundAccount: false,
      balanceLoading: false,
      showFundHistory: false,
    };
  },
  computed: {
    ...mapState({
      creditWallet: (state) => state?.dashboard?.userWallet?.creditWallet,
      instaPayWallet: (state) => state?.dashboard?.userWallet?.instaPayWallet,
    }),
  },
  mounted() {
    this.getWallet();
  },
  methods: {
    ...mapActions("dashboard", ["getUserWallet"]),
    ...mapActions("notification", ["showAlert"]),
    getWallet() {
      this.balanceLoading = true;
      this.getUserWallet()
        .then(() => {
          this.balanceLoading = false;
        })
        .catch(() => {
          this.balanceLoading = false;
        });
    },
    completed() {
      this.showFundAccount = false;
      this.showAlert({
        display: true,
        type: "success",
        description: `Wallet successfully funded`,
      });
    },
    closeFundWallet() {
      this.getWallet();
      this.showFundAccount = false;
    },
  },
};
</script>

<style scoped>
.spending-container {
  max-width: 36.563rem;
  margin: auto;
}
.spending-card {
  display: grid;
  grid-template-columns: 12% 1fr;
}
</style>