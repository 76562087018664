<template>
  <div class="h-full w-full grid p-2">
    <div>
      <h6 class="text-brandPurple text-xl font-bold">
        Setup bank account tokenization
      </h6>
      <p class="text-grey mt-4">
        We have sent an SMS containing unique OTP and USSD codes to your phone
        number: 08022*****54. Kindly confirm the codes to proceed.
      </p>
      <h2 class="text-grey font-bold py-5">Why you need to do this?</h2>
      <ul class="list-disc pl-4">
          <li>We want to ensure that your payment experience is smooth and easy for this first payment and for every other payment you make with Zilla.</li>
          <li class="mt-4">When you proceed, you will be sent steps to setup debit instructions on your bank account. Kindly complete this process to setup your spending limit.</li>
      </ul>
    </div>

    <div class="flex items-end mt-20">
      <Button class="w-full" text="Proceed" @click="$emit('code')" />
    </div>
  </div>
</template>

<script>
import { Input } from "@/UI/Input";
import { Button } from "@/UI/Button";
export default {
  components: {
    Input,
    Button,
  },
};
</script>

<style>
</style>