<template>
  <div class="text-center py-10">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="78"
      height="62"
      viewBox="0 0 78 62"
      fill="none"
      class="mx-auto"
    >
      <path d="M4 34.9701L28.6429 54L73 4" stroke="#6A30C3" stroke-width="11" />
      <path
        d="M4 34.9701L28.6429 54L73 4"
        stroke="url(#paint0_linear_21_2290)"
        stroke-width="11"
      />
      <defs>
        <linearGradient
          id="paint0_linear_21_2290"
          x1="-427.857"
          y1="244"
          x2="185.813"
          y2="510.308"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.38059" stop-color="#C6003B" />
          <stop offset="0.491928" stop-color="#6A30C3" />
          <stop offset="0.600732" stop-color="#63DFDF" />
        </linearGradient>
      </defs>
    </svg>
    <h3 class="text-2xl font-bold mt-10 text-brandPurple">
      Limit setup <br />
      completed
    </h3>
    <p class="text-grey mt-4">
      You have set a spending limit of N20,000 on your account.
    </p>

    <div class="mt-10">
      <Button
        text="Done"
        width="w-full"
        p="py-3 px-8"
        class="place-self-end"
        outline
        @click="$emit('successFunction')"
      />
    </div>
  </div>
</template>
<script>
import { Button } from "@/UI/Button";
export default {
  components: {
    Button,
  },
};
</script>

