var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "spending-container" },
        [
          _c("BackNav", {
            on: {
              click: function ($event) {
                return _vm.$router.push("/drawer")
              },
            },
          }),
          _c(
            "div",
            { staticClass: "mt-6" },
            [
              _c("Card", [
                _c("p", { staticClass: "text-grey" }, [
                  _vm._v("Credit balance"),
                ]),
                _vm.creditWallet
                  ? _c(
                      "div",
                      [
                        _vm.creditWallet.totalBalance
                          ? _c(
                              "h2",
                              {
                                staticClass:
                                  "text-brandPurple font-bold text-xl",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatMoney")(
                                        Math.abs(_vm.creditWallet.totalBalance)
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _c("Skeleton", {
                              attrs: { height: "1.5rem", width: "30%" },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.creditWallet
                  ? _c(
                      "div",
                      [
                        _vm.creditWallet.balance
                          ? _c("p", { staticClass: "text-grey mb-5" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatMoney")(
                                      Math.abs(_vm.creditWallet.balance)
                                    )
                                  ) +
                                  " spent "
                              ),
                            ])
                          : _c("Skeleton", {
                              staticClass: "mt-2",
                              attrs: { height: "1rem", width: "40%" },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c("Card", { staticClass: "mt-6" }, [
            _vm.instaPayWallet
              ? _c(
                  "div",
                  [
                    _vm.instaPayWallet.balance
                      ? _c("h2", { staticClass: "font-bold text-xl" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("formatMoney")(
                                  _vm.instaPayWallet.balance
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _c("Skeleton", {
                          attrs: { height: "1.5rem", width: "30%" },
                        }),
                  ],
                  1
                )
              : _vm._e(),
            _c("p", { staticClass: "text-grey" }, [_vm._v("Wallet balance")]),
            _c(
              "div",
              { staticClass: "flex justify-end items-center mt-4" },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "text-brandPurple text-sm font-bold mr-4 cursor-pointer",
                    on: {
                      click: function ($event) {
                        _vm.showFundHistory = true
                      },
                    },
                  },
                  [_vm._v(" Wallet history ")]
                ),
                _c("SecondaryButton", {
                  attrs: { text: "Fund wallet", fontSize: "text-sm" },
                  on: {
                    click: function ($event) {
                      _vm.showFundAccount = true
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showLimit, hideClose: "" },
          on: {
            close: function ($event) {
              _vm.showLimit = false
            },
          },
        },
        [
          _c("SetLimit", {
            on: {
              create: function ($event) {
                ;(_vm.showLimit = false), (_vm.showTokenizationModal = true)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showTokenizationModal, hideClose: "" },
          on: {
            close: function ($event) {
              _vm.showTokenizationModal = false
            },
          },
        },
        [
          _c("ShowTokenization", {
            on: {
              code: function ($event) {
                ;(_vm.showTokenizationModal = false), (_vm.showOtpModal = true)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showOtpModal, hideClose: "" },
          on: {
            close: function ($event) {
              _vm.showOtpModal = false
            },
          },
        },
        [
          _c("Showotp", {
            on: {
              success: function ($event) {
                ;(_vm.showOtpModal = false), (_vm.showSuccessModal = true)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showSuccessModal, hideClose: "" },
          on: {
            close: function ($event) {
              _vm.showSuccessModal = false
            },
          },
        },
        [
          _c("Success", {
            on: {
              successFunction: function ($event) {
                _vm.showSuccessModal = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            display: _vm.showFundAccount,
            title: "Add money to account",
          },
          on: { close: _vm.closeFundWallet },
        },
        [
          _c("FundAccount", {
            on: {
              completed: _vm.completed,
              close: function ($event) {
                _vm.showFundAccount = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showFundHistory, title: "Your Wallet" },
          on: {
            close: function ($event) {
              _vm.showFundHistory = false
            },
          },
        },
        [
          _vm.instaPayWallet
            ? _c(
                "div",
                [
                  _c("FundHistory", {
                    attrs: {
                      balance: _vm.instaPayWallet.balance,
                      showBalance: false,
                    },
                    on: {
                      fund: function ($event) {
                        _vm.showFundHistory = false
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }