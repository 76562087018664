var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-full w-full grid p-2" }, [
    _vm._m(0),
    _c("div", { staticClass: "flex items-end mt-20" }, [
      _c(
        "div",
        { staticClass: "w-full" },
        [
          _c("Button", {
            staticClass: "w-full",
            attrs: { text: "Didn’t get code; retry in 00:59", outline: "" },
          }),
          _c("Button", {
            staticClass: "w-full mt-4",
            attrs: { text: "I have completed the request" },
            on: {
              click: function ($event) {
                return _vm.$emit("success")
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h6", { staticClass: "text-brandPurple text-xl font-bold" }, [
        _vm._v(" Kindly check your messages "),
      ]),
      _c("p", { staticClass: "text-grey mt-4" }, [
        _vm._v(
          " We have sent an SMS containing unique OTP and USSD codes to your phone number: 08022*****54. Kindly confirm the codes to proceed. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }