<template>
  <div class="h-full w-full grid p-2">
    <div>
      <h6 class="text-brandPurple text-xl font-bold">Set spending limit</h6>
      <p class="text-grey mt-4">
        Spend without worry when you set a spending limit on your account
      </p>
      <Input
        width="w-full"
        class="mt-6"
        placeholder="Enter your spending limit"
        type="number"
      />
    </div>
    <div class="flex items-end mt-20">
      <Button class="w-full" text="Create limit" @click="$emit('create')" />
    </div>
  </div>
</template>

<script>
import { Input } from "@/UI/Input";
import { Button } from "@/UI/Button";
export default {
  components: {
    Input,
    Button,
  },
};
</script>

<style>
</style>