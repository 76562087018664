<template>
  <Finance />
</template>
<script>
import Finance from "@/components/Finance";
export default {
  components: {
    Finance,
  },
};
</script>
