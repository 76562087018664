<template>
  <div class="h-full w-full grid p-2">
    <div>
      <h6 class="text-brandPurple text-xl font-bold">
        Kindly check your messages
      </h6>
      <p class="text-grey mt-4">
        We have sent an SMS containing unique OTP and USSD codes to your phone
        number: 08022*****54. Kindly confirm the codes to proceed.
      </p>
    </div>

    <div class="flex items-end mt-20">
      <div class="w-full">
        <Button class="w-full" text="Didn’t get code; retry in 00:59" outline />
        <Button
          class="w-full mt-4"
          text="I have completed the request"
          @click="$emit('success')"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { Input } from "@/UI/Input";
import { Button } from "@/UI/Button";
export default {
  components: {
    // Input,
    Button,
  },
};
</script>

<style>
</style>